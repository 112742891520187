<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Detalle de actividades de usuario</h1>
        </div>
        <div class="form-group">
          <button type="text" class="btn btn-secondary" v-on:click="back()">Regresar</button>
        </div>
        <!--
        <div class="row">
          <div class="col-md-12">
              <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords})">
                  <div class="input-group">
                    <input type="text" v-model="keywords" class="form-control" placeholder="" aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-outline-primary">Buscar</button>
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-doc v-on:search="searchItems" v-bind:item="search"></search-doc>
                    </div>
                  </div>                
                </form>
              </div>
          </div>
        </div>
        -->
        <div class="row">
          <div class="col-md-12">
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="form-group text-center">
              <h2 class="text-info">{{ $route.params.nombre }}</h2>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-sm display nowrap table-striped">
                  <thead>
                    <tr style="font-weight:bold">
                      <td>Clase documento</td>
                      <td>Año</td>
                      <td>Consecutivo</td>
                      <td>Fecha radicación</td>
                      <td></td>
                      <td>Entidad</td>
                      <td>Asunto</td>
                      <td>Dependencia</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in items" v-bind:style="{ color: item.color }">
                      <td>
                        <a href="#" @click="openItem(item._id)" v-bind:style="{ color: item.color }">{{ item.tcr_nmbre }}</a>
                      </td>
                      <td>{{ item.dcm_ano }}</td>
                      <td>{{ item.dcm_cdgo }}</td>
                      <td>{{ item.dcm_fcharad | formatDate }}</td>
                      <td>
                        <a href="#" v-bind:style="{ color: item.color }">
                          <git-commit-icon class="custom-class" @click="consultarDetalles(items, index)"></git-commit-icon>
                        </a>
                      </td>
                      <td>{{ item.dcm_entidad }}</td>
                      <td>{{ item.dcm_asunto }}</td>
                      <td>{{ item.dep_nmbre }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <div id="components">
      <search-doc v-on:search="searchItems()" v-bind:item="search"></search-doc>
      <timeline :detalles="detalles" :items="detalles_items"></timeline>      
    </div>    
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import { formatDate } from './../../plugins/filters';
import SearchDoc from './SearchDoc.vue';
import Timeline from './Timeline.vue';
import { RefreshCwIcon, GitCommitIcon } from 'vue-feather-icons';
import { tiempoUtilizado } from '../../utils';

export default {
  components: {
    SearchDoc,
    Timeline,
    RefreshCwIcon, GitCommitIcon
  },
  data(){
    return{
      items: [],
      tpocors: [],
      dependencias: [],
      usuarios: [],
      estados: [],
      keywords:'',
      search: {},
      filter: false, // Items are filtered
      detalles: {},
      detalles_items: [],
      dnl: []
    }
  },
  filters: {
    formatDate
  },
  computed: {

  },
  mounted: function() {
    feather.replace();
  },
  created: function(){
    this.fetchTpocor(err => {
      if (err){
        alert('Error al consultar las clases de documentos.');
      } else {
        this.fetchDependencia(err => {
          if (err){
            alert('Error al consultar las dependencias.');
          } else {
            this.fetchUsuario(err => {
              if (err){
                alert('Error al consultar los usuarios.');
              } else {
                this.fetchEstado(err => {
                  if (err){
                    alert('Error al consultar los estados.');
                  } else {
                    this.fetchDianol(err => {
                      if (err){
                        alert('Error al consultar los días no laborales.');
                      } else {
                        if (this.$route.params.search) {
                          this.search = this.$route.params.search;
                        }
                        this.searchItems(this.search);
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  },
  methods: {
    fetchTpocor(callback){
      let uri = '/tpocors/correspondencia/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },    
    fetchUsuario(callback){
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri)
      .then(response => {
        this.usuarios = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchEstado(callback){
      let uri = '/estados/' + String(this.$store.state.company);
      this.axios.get(uri)
      .then(response => {
        this.estados = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDianol(callback){
      var p = {};
      p.cmp_id = this.$store.state.company;

      let uri = '/dianols/total';
      this.axios.post(uri, p)
      .then((response) => {
        this.dnl = response.data;
        return callback(false);
      })
      .catch((err) => {
        return callback(true);
      });
    },    
    searchItems(p){
      if (p != null){
        if (p.key){
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
      } else {
        p = {};
      }

      this.message = 'Buscando documentos';
      p.cmp_id = this.$store.state.company;
      p.usu_id = this.$route.params.usu_id;

      let uri = '/docs/activity';
      this.axios.post(uri, p)
      .then(response => {
        this.items = response.data;
        this.search = p;
        this.filter = true;

        for(let i = 0; i < this.items.length; i++){
          for(let j = 0; j < this.tpocors.length; j++){
            if (String(this.items[i].tcr_id) == String(this.tpocors[j]._id)){
              this.$set(this.items[i], 'tcr_nmbre', this.tpocors[j].tcr_nmbre);
              j = this.tpocors.length;
            }
          }

          for(let j = 0; j < this.dependencias.length; j++){
            if (String(this.items[i].dep_id) == String(this.dependencias[j]._id)){
              this.$set(this.items[i], 'dep_nmbre', this.dependencias[j].dep_nmbre);
              j = this.dependencias.length;
            }
          }

        }     

        // Calcular tiempo de respuesta
        for(let i = 0; i < this.items.length; i++){
          var color = 'black';

          if (this.items[i].dcm_rspsta){
            var restante = parseInt(this.items[i].dcm_tres) - tiempoUtilizado(this.items[i].dcm_fcharad, new Date(), this.dnl);

            if (restante < 0){
              color = 'red';
            } else if (restante == 0){
              color = '#FF8C00';
            } else {
              color = 'green';
            }
          } else {
            color = 'black';
          }
          this.$set(this.items[i], 'color', color);
        }        
      })
      .catch(err => {
        console.log(err);
        this.message = '¡Error al buscar los documentos' + err;
      });
    },
    closeSearch(){
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch () {
      this.search = {};
    },
    tpocorName(k){
      for (var i = 0; i < this.tpocors.length; i++){
        if (String(k) == String(this.tpocors[i]._id)){
          return this.tpocors[i].tcr_nmbre;
        }
      }
      return '';
    },
    consultarDetalles(array, index){
      this.detalles = {};
      this.detalles.doc = this.tpocorName(array[index].tcr_id) + '-' + array[index].dcm_ano + '-' + array[index].dcm_cdgo;
      this.detalles_items = [];
      var items = [];
      items.push({ from: array[index].dcm_fcharad, title: array[index].usu_nmbre, description: '(Radicado)', step:'' });
      let uri = '/docs/seguimientos';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, { id: array[index]._id })
      .then((response) => {        
        console.log(response.data);
        var seg = response.data.seguimientos;
        for (let i = 0; i < seg.length; i++){
          for (let j = 0; j < this.usuarios.length; j++){
            if (seg[i].ParaUsuarioId == this.usuarios[j]._id){
              seg[i].ParaUsuarioNombre = this.usuarios[j].usu_nmbre + ' ' + this.usuarios[j].usu_aplldo;
              j = this.usuarios.length;
            }
          }
          for (let s = 0; s < this.usuarios.length; s++){
            if (seg[i].DeUsuarioId == this.usuarios[s]._id){
              seg[i].DeUsuarioNombre = this.usuarios[s].usu_nmbre + ' ' + this.usuarios[s].usu_aplldo;
              s = this.usuarios.length;
            }
          }
          for (let k = 0; k < this.estados.length; k++){
            if (seg[i].EstadoId == this.estados[k]._id){
              seg[i].EstadoNombre = this.estados[k].Nombre;
              k = this.estados.length;
            }
          }
          var t2 = new Date(seg[i].Fecha);
          var t1 = new Date(items[items.length - 1].from);
          var dif = Math.abs(t2 - t1);
          //debugger;
          var step = Math.floor(dif/(1000*60*60*24));
          items.push({ from: seg[i].Fecha, deUsuario: seg[i].DeUsuarioNombre, title: seg[i].ParaUsuarioNombre, description: '(' + seg[i].EstadoNombre + ') ' + seg[i].Descripcion, 'step': '(' + step + 'D+)' });
        }
        this.detalles_items = items;
        $('#timelineModal').modal('show');
      })
      .catch((err) => {
        
      });
    },
    openItem(id){
      this.$router.replace({ name: 'CreateDoc', params: { 'id': id, 'search': this.search } });
    },
    back(){
      //this.$router.replace({ name: 'DisplayActivity', params: { search: this.$route.params.search } });
      this.$router.replace({ name: 'DisplayActivity' });
    }
  }
}
</script>